<template>
    <section class="page-container">
        <div class="image-404"></div>
    </section>
</template>

<style lang="less" scoped>
.page-container {
    padding: 10%;
    .image-404 {
        height: 480px;
        background: url(../../assets/img/image_404.png) no-repeat center;
    }
}
</style>
